<template>
  <data-table
    :headers="headers"
    :items="obCollection.getModelList()"
    :last-page="serverLastPage"
    :loading="bLocalLoading"
    :options="pagination"
    :total="serverItemsLength"
    btn-action-item-key-id="id"
    btn-action-path="migrations"
    hide-btn-action-update
    hide-btn-action-view
    is-route-name
    @delete="deleteItem"
    @update:sort-by="onSortBy"
    @update:sort-desc="onSortDesc"
    @update:options="onPagination"
    @update:page="onPage"
  >
    <template v-slot:[`item.name`]="{ item }">
      <v-btn
        :to="{ name: 'migrations.view', params: { id: item.id } }"
        color="primary"
        text
      >
        {{ item.name }}
      </v-btn>
    </template>

    <template #top>
      <migrations-form
        :key="$route.params.id"
        v-model="obItem"
        :open.sync="displayForm"
      />
    </template>
  </data-table>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import MigrationsMixin from "@/modules/migrations/mixins/MigrationsMixin";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import type { MigrationMonitorData } from "@planetadeleste/vue-mc-gw";
import { toString } from "lodash";
import MigrationsForm from "@/modules/migrations/components/Form.vue";

@Component({
  components: {
    MigrationsForm,
  },
})
export default class MigrationsList extends Mixins(MigrationsMixin) {
  isList = true;

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "name", value: "name" },
      { text: "progress", value: "progress" },
    ];
    this.setDTHeaders(arHeaders);

    this.index();
  }

  onRegisterEvents() {
    this.addEvent(
      `${this.sModelName}.after.save`,
      async (obData: MigrationMonitorData) => {
        // Move to view route
        if (this.$route.name === "migrations.create") {
          await this.$router.push({
            name: "migrations.view",
            params: { id: toString(obData.id) },
          });
        }
      }
    );
  }
}
</script>
